import { generateMockId } from "@m/api/testing";
import { dt } from "@m/utils";

import { MeteredChargeType } from "../api";

import { EXAMPLE_DIMENSIONS } from "./dimension.examples";

const ExampleMeteredCharge = {
  id: generateMockId(),
  quantity: 20,
  sentTimestamp: dt.now().minus({ weeks: 1, minutes: 5 }).toISO(),
  marketplaceProductCode: generateMockId(),
  successfullySent: true,
  successfullySentTimestamp: dt.now().minus({ weeks: 1 }).toISO(),
  company: {
    id: "789",
  },
  dimension: EXAMPLE_DIMENSIONS[0],
  account: {
    id: "123",
    name: "John Smith",
  },
} satisfies MeteredChargeType;

export const EXAMPLE_METERED_CHARGE = ExampleMeteredCharge;
export const EXAMPLE_METERED_CHARGES = [
  ExampleMeteredCharge,
  {
    ...ExampleMeteredCharge,
    ...{
      quantity: 30,
      successfullySentTimestamp: dt.now().toISO(),
      account: {
        __typename: "User",
        id: "234",
        name: "Luca Pasedena",
      },
    },
  },
  {
    ...EXAMPLE_METERED_CHARGE,
    ...{
      dimension: EXAMPLE_DIMENSIONS[1],
      successfullySentTimestamp: null,
      successfullySent: false,
      quantity: 10,
      account: null,
    },
  },
] satisfies MeteredChargeType[];
