import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";

import {
  MarketplaceMeteringStatus,
  MarketplaceRegistrationStatus,
} from "@m/api/v4/types";
import { Badge, Dropdown, DropdownItem, Suspensed, useModalState } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { SubscriptionInfoBadge } from "@atlas/components";

import { SubscriptionDetailsType } from "../api";

import { StopMeteringModal } from "./StopMeteringModal";

interface Props {
  loading: boolean;
  subscriptionDetails: SubscriptionDetailsType | null;
}

export const SubscriptionDetailsTable = ({
  loading,
  subscriptionDetails,
}: Props) => {
  const {
    awsAccountId = "",
    privateOfferId = "",
    registrationStatus = null,
    expirationTime = null,
    subscribeTime = null,
    unsubscribeTime = null,
    payerAccountId = "",
    meteringStatus = null,
    customerIdentifier = "",
    onboardingCompleteTimestamp = null,
  } = subscriptionDetails || {};

  const subscriptionActions = useModalState();

  const isMeteringDisabled =
    meteringStatus === MarketplaceMeteringStatus.Inactive;
  const isUnsubscribed =
    registrationStatus === MarketplaceRegistrationStatus.Unsubscribed;

  return (
    <section
      id="subscription-information"
      className="w-full flex-row place-content-between p-1"
    >
      <div className="flex-col">
        <div
          id={`subscription-details`}
          className="mb-0.5 mr-6 flex h-5 min-w-[125px] flex-row items-center gap-2 text-sm font-semibold"
        >
          <h2>Subscription</h2>
          <Suspensed height="16px" width="46px" loading={loading}>
            <SubscriptionInfoBadge
              status={registrationStatus}
              aria-label="Subscription Status"
            />
            {isMeteringDisabled && (
              <Badge label="Metering Disabled" status="negative" size="small" />
            )}
            <Dropdown
              className="w-[165px] p-1"
              direction="bottom-start"
              trigger={
                <EllipsisVerticalIcon
                  aria-label="Subscription Actions"
                  className="h-2.5 w-2.5"
                />
              }
            >
              <DropdownItem
                className="p-1"
                onClick={subscriptionActions.open}
                disabled={isMeteringDisabled}
              >
                Stop Metering
              </DropdownItem>
            </Dropdown>
          </Suspensed>
        </div>
        <ol className="flex w-full flex-wrap content-evenly">
          <SubscriptionItem
            title="Subscriber Account ID"
            value={awsAccountId}
            loading={loading}
          />
          <SubscriptionItem
            title="Private Offer ID"
            value={privateOfferId}
            loading={loading}
          />
          <SubscriptionItem
            title="Payer Account ID"
            value={payerAccountId}
            loading={loading}
          />
          <SubscriptionItem
            title="Onboarded"
            value={
              formatFullDate(onboardingCompleteTimestamp, "MMMM d, yyyy") ||
              null
            }
            loading={loading}
          />
          <SubscriptionItem
            title="Subscribed"
            value={formatFullDate(subscribeTime, "MMMM d, yyyy") || null}
            loading={loading}
          />
          <SubscriptionItem
            title={isUnsubscribed ? "Unsubscribed" : "Expires"}
            value={
              isUnsubscribed
                ? formatFullDate(unsubscribeTime, "MMMM d, yyyy")
                : formatFullDate(expirationTime, "MMMM d, yyyy")
            }
            loading={loading}
          />
        </ol>
      </div>
      <StopMeteringModal
        open={subscriptionActions.isOpen}
        onClose={subscriptionActions.close}
        customerIdentifier={customerIdentifier}
      />
    </section>
  );
};

const SubscriptionItem = ({
  title,
  loading,
  value,
}: {
  title: string;
  loading: boolean;
  value: ReactNode;
}) => {
  const id = `${title.replace(/\s+/g, "-").toLowerCase()}`;
  return (
    <li className="mr-4 flex min-h-[100px] w-[170px] flex-col pb-2 text-xs font-medium">
      <label id={id}>{title}</label>
      <div
        className="break-words pt-0.5 font-light text-subdued"
        aria-labelledby={id}
      >
        <Suspensed height="16px" width="82px" loading={loading}>
          {value || "—"}
        </Suspensed>
      </div>
    </li>
  );
};
