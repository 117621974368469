import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  MarketplaceCatalogItemFilter,
  MarketplaceDimensionFactorUnit,
} from "@m/api/v4/types";

export const GET_SUBSCRIPTION_MANUAL_DIMENSIONS = gql(/* GraphQL */ `
  query getSubscriptionManualDimensions($where: MarketplaceCatalogItemFilter) {
    marketplaceCatalogItems(where: $where) {
      edges {
        node {
          manualMeteringEnabledDimensions {
            id
            apiName
            atlasName
            atlasDescription
            dimensionFactorUnit
            pricingConsumption
          }
        }
      }
    }
  }
`);

export type ManuallyMeteredDimensionType = {
  id: string;
  apiName?: string | null;
  atlasName: string;
  atlasDescription: string;
  dimensionFactorUnit?: MarketplaceDimensionFactorUnit | null;
  pricingConsumption?: number | null;
};

export const useSubscriptionManualDimensions = (
  marketplaceProductCode: string | null
) => {
  const where: MarketplaceCatalogItemFilter = {
    code: marketplaceProductCode,
  };

  const { data, ...result } = useQuery(GET_SUBSCRIPTION_MANUAL_DIMENSIONS, {
    variables: {
      where,
    },
  });

  const dimensions: ManuallyMeteredDimensionType[] =
    data?.marketplaceCatalogItems?.edges[0]?.node?.manualMeteringEnabledDimensions?.filter(
      (dimension): dimension is NonNullable<typeof dimension> =>
        dimension !== null
    ) ?? [];

  return {
    data: { dimensions },
    ...result,
  };
};
