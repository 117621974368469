import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
  loadingState,
  successState,
} from "@m/api/testing";
import { MarketplaceDimensionFactorUnit } from "@m/api/v4/types";

import { EXAMPLE_DIMENSIONS } from "../examples";

import { GET_SUBSCRIPTION_MANUAL_DIMENSIONS } from "./useSubscriptionManualDimensions";

const mock: WildcardMockedResponse = {
  request: {
    query: GET_SUBSCRIPTION_MANUAL_DIMENSIONS,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      marketplaceCatalogItems: {
        edges: [
          {
            node: {
              manualMeteringEnabledDimensions: EXAMPLE_DIMENSIONS.map(
                (dimension) => ({
                  ...dimension,
                  apiName: "API_NAME",
                  dimensionFactorUnit: MarketplaceDimensionFactorUnit.Hours,
                  pricingConsumption: 100,
                })
              ),
            },
          },
        ],
      },
    },
  },
};
export const MOCK_GET_SUBSCRIPTION_MANUAL_DIMENSIONS = successState(mock);
export const MOCK_GET_SUBSCRIPTION_MANUAL_DIMENSIONS_ONE = successState({
  ...mock,
  result: {
    data: {
      marketplaceCatalogItems: {
        edges: [
          {
            node: {
              manualMeteringEnabledDimensions: [
                {
                  apiName: "PlatFeeOverage",
                  atlasName: "Overage Hours",
                  atlasDescription:
                    "Hours beyond the monthly pool hours approved by the customer",
                  dimensionFactorUnit: "HOURS",
                  pricingConsumption: 320,
                  id: "TWFya2V0cGxhY2VEaW1lbnNpb246MTA=",
                },
              ],
            },
          },
        ],
      },
    },
  },
});

export const MOCK_GET_SUBSCRIPTION_MANUAL_DIMENSIONS_EMPTY = successState({
  request: {
    query: GET_SUBSCRIPTION_MANUAL_DIMENSIONS,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      marketplaceCatalogItems: {
        edges: [
          {
            node: {
              manualMeteringEnabledDimensions: [],
            },
          },
        ],
      },
    },
  },
});

export const MOCK_GET_SUBSCRIPTION_MANUAL_DIMENSIONS_LOADING = loadingState(
  MOCK_GET_SUBSCRIPTION_MANUAL_DIMENSIONS
);
