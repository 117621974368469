import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { PageTitle } from "@atlas/components";
import { PATHS } from "@atlas/constants";

import { Sidebar } from "./CustomerDetailsSidebar";

export const CustomerDetailsLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  return (
    <div className="min-w-screen flex h-full min-h-screen w-full flex-col p-3">
      <PageTitle
        title={
          <div
            role="button"
            className="inline-flex grow-0 pb-2 align-middle text-sm font-semibold"
            onClick={() => navigate(PATHS.ROOT)}
          >
            <ChevronLeftIcon height="16px" className="my-auto mr-0.5" />
            Customers
          </div>
        }
      />
      <div className="flex min-w-[450px] grow">
        <Sidebar />
        <div className="flex grow flex-col overflow-auto">{children}</div>
      </div>
    </div>
  );
};
