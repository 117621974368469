import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChangeEvent } from "react";

import { Button, Input, Select, Suspensed } from "@m/ui";
import { toProperCase } from "@m/utils";

import { type ManuallyMeteredDimensionType } from "../api";

import { MeteredChargeInformation } from "./AddMeteredChargeModal";

interface Props {
  subscriptions: MeteredChargeInformation[];
  selectedSubscription: MeteredChargeInformation | null;
  dimensions: ManuallyMeteredDimensionType[];
  loadingDimensions: boolean;
  selectedDimension: ManuallyMeteredDimensionType | null;
  dimensionQuantity: number;
  onDimensionUnitsChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubscriptionChange: (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  onDimensionChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  onReviewClick: () => void;
}

export const AddMeteredChargeForm = ({
  subscriptions,
  selectedSubscription,
  loadingDimensions,
  dimensions,
  selectedDimension,
  dimensionQuantity,
  onSubscriptionChange,
  onDimensionUnitsChange,
  onDimensionChange,
  onReviewClick,
}: Props) => {
  const disableReviewButton =
    loadingDimensions || !dimensionQuantity || dimensionQuantity === 0;
  const unitsLabel =
    selectedDimension?.dimensionFactorUnit?.toLowerCase() || "units";

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-col gap-2">
        <div>
          <label htmlFor="subscriptions-select" className="font-medium">
            Subscription
          </label>
          <Select
            id="subscriptions-select"
            aria-label="Subscriptions Select"
            aria-describedby="subscriptions-description"
            disabled={subscriptions.length <= 1}
            value={selectedSubscription?.displayName || ""}
            onChange={onSubscriptionChange}
            options={subscriptions.map((sub) => (
              <option key={sub?.marketplaceProductCode}>
                {sub?.displayName}
              </option>
            ))}
          />
          <p id="subscriptions-description" className="sr-only">
            Select a subscription to charge.
          </p>
        </div>
        <div>
          <label htmlFor="dimensions-select" className="font-medium">
            Dimension
          </label>
          <Select
            id="dimensions-select"
            aria-label="Dimensions Select"
            aria-describedby="dimensions-description"
            className="mb-2"
            disabled={dimensions.length <= 1 || loadingDimensions}
            value={selectedDimension?.atlasName || ""}
            onChange={onDimensionChange}
            options={dimensions.map((dimension) => (
              <option key={dimension?.id} value={dimension?.atlasName}>
                {dimension?.atlasName}
              </option>
            ))}
          />
          <p id="dimensions-description" className="sr-only">
            Select a metering dimension.
          </p>
          <label htmlFor="dimension-unit-input" className="mb-2 font-medium">
            <Suspensed
              height="16px"
              width="100%"
              className="inline-flex"
              loading={loadingDimensions}
            >
              {toProperCase(selectedDimension?.dimensionFactorUnit || "")}
              {disableReviewButton && (
                <span role="alert" className="ml-2 text-xs text-subdued">
                  {"("}Please enter a valid number of {unitsLabel} to proceed
                  {")"}
                </span>
              )}
            </Suspensed>
          </label>
          <Input
            id="dimension-unit-input"
            type="number"
            min={0}
            value={dimensionQuantity}
            disabled={loadingDimensions}
            onChange={onDimensionUnitsChange}
            aria-label="Dimension Unit Input"
            aria-describedby="dimension-units-description"
          />
          <p id="dimension-units-description" className="sr-only">
            Enter the number of units for the selected dimension.
          </p>
        </div>
      </div>
      <Button
        onClick={onReviewClick}
        disabled={disableReviewButton}
        type="button"
        fill="solid"
        kind="primary"
        rightIcon={ArrowRightIcon}
        aria-disabled={disableReviewButton}
      >
        Review Charge
      </Button>
    </div>
  );
};
