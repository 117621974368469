import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
  loadingState,
  successState,
} from "@m/api/testing";

import { EXAMPLE_METERED_CHARGES } from "../examples/meteredCharges.example";

import { GET_METERED_CHARGES } from "./useMeteredCharges";

const mock: WildcardMockedResponse = {
  request: {
    query: GET_METERED_CHARGES,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      marketplaceMeteringTransactions: {
        edges: EXAMPLE_METERED_CHARGES.map((charge) => ({
          node: charge,
        })),
      },
    },
  },
};
export const MOCK_GET_MARKETPLACE_METERED_CHARGES = successState(mock);
export const MOCK_GET_MARKETPLACE_METERED_CHARGES_EMPTY = successState({
  ...mock,
  result: {
    data: {
      marketplaceMeteringTransactions: {
        edges: [],
      },
    },
  },
});

export const MOCK_GET_MARKETPLACE_METERED_CHARGES_LOADING = loadingState(
  MOCK_GET_MARKETPLACE_METERED_CHARGES
);
