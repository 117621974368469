import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { type MeterMarketplaceProductUsageInput } from "@m/api/v4/types";
import { useAuth } from "@m/login";
import { Modal } from "@m/ui";

import {
  type ManuallyMeteredDimensionType,
  useCreateMeteredCharge,
  useSubscriptionManualDimensions,
} from "../api";

import { AddMeteredChargeForm } from "./AddMeteredChargeForm";
import { AddMeteredChargeReview } from "./AddMeteredChargeReview";

export type MeteredChargeInformation = {
  marketplaceProductCode: string | undefined;
  customerIdentifier: string | undefined;
  displayName: string | undefined;
};

interface Props {
  onClose: () => void;
  isOpen: boolean;
  subscriptions: MeteredChargeInformation[];
}

export const AddMeteredChargeModal = ({
  subscriptions = [],
  isOpen,
  onClose,
}: Props) => {
  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState<"add" | "review">("add");
  const [selectedSubscription, setSelectedSubscription] =
    useState<MeteredChargeInformation | null>(subscriptions[0] ?? null);

  const {
    data: { dimensions } = { dimensions: [] },
    loading: loadingDimensions,
  } = useSubscriptionManualDimensions(
    selectedSubscription?.marketplaceProductCode || null
  );

  const [createMeteredCharge, { loading: createMeteredChargeLoading }] =
    useCreateMeteredCharge();

  const [selectedDimension, setSelectedDimension] =
    useState<ManuallyMeteredDimensionType | null>(null);
  const [dimensionQuantity, setDimensionQuantity] = useState(0);

  useEffect(() => {
    if (
      !loadingDimensions &&
      dimensions.length > 0 &&
      selectedSubscription &&
      (selectedDimension === null || !dimensions.includes(selectedDimension))
    ) {
      setSelectedDimension(dimensions[0]);
      setDimensionQuantity(0);
    }
  }, [loadingDimensions, dimensions, selectedSubscription, selectedDimension]);

  if (!selectedSubscription) return null;
  if (dimensions.length === 0 && !loadingDimensions) {
    return null;
  }

  const title =
    currentPage === "add"
      ? "Add AWS Marketplace Metered Charge"
      : "Review Charges";

  const handleReviewClick = () => setCurrentPage("review");
  const handleModifyClick = () => setCurrentPage("add");

  const handleChargeToAWS = () => {
    if (
      !user ||
      !selectedDimension?.apiName ||
      !selectedSubscription?.marketplaceProductCode ||
      !selectedSubscription.customerIdentifier
    ) {
      onClose();
      return toast.error(
        "There was a problem creating a manually metered charge for this subscription"
      );
    }

    const payload: MeterMarketplaceProductUsageInput = {
      accountId: user.id,
      customerIdentifier: selectedSubscription.customerIdentifier,
      pricingDimension: selectedDimension.apiName,
      productCode: selectedSubscription.marketplaceProductCode,
      quantity: dimensionQuantity,
    };

    createMeteredCharge(payload);
    onClose();
  };

  const handleDimensionUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = Number(e.target.value);
    if (!isNaN(parsedValue)) {
      setDimensionQuantity(parsedValue);
    }
  };

  const handleSubscriptionChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const subscription = subscriptions.find(
      (sub) => sub?.displayName === e.target.value
    );
    if (subscription) setSelectedSubscription(subscription);
  };

  const handleDimensionChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const dimension = dimensions.find(
      (dim) => dim?.atlasName === e.target.value
    );
    if (dimension) {
      setSelectedDimension(dimension);
      setDimensionQuantity(0);
    }
  };

  return (
    <Modal
      open={isOpen}
      size="lg"
      title={title}
      onClose={onClose}
      aria-label="Add Metered Charge Modal"
    >
      {currentPage === "add" ? (
        <AddMeteredChargeForm
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          dimensions={dimensions}
          loadingDimensions={loadingDimensions}
          selectedDimension={selectedDimension}
          dimensionQuantity={dimensionQuantity}
          onSubscriptionChange={handleSubscriptionChange}
          onDimensionUnitsChange={handleDimensionUnitsChange}
          onDimensionChange={handleDimensionChange}
          onReviewClick={handleReviewClick}
        />
      ) : (
        <AddMeteredChargeReview
          onModifyClick={handleModifyClick}
          onChargeToAWS={handleChargeToAWS}
          dimensionQuantity={dimensionQuantity}
          subscription={selectedSubscription}
          dimension={selectedDimension}
          createMeteredChargeLoading={createMeteredChargeLoading}
        />
      )}
    </Modal>
  );
};
