import { Tooltip } from "@m/ui";
import { formatFullDate } from "@m/utils";

interface Props {
  successfullySentTimestamp: string | null;
  successfullySent: boolean;
  sentTimestamp: string;
}

export const MeteredChargeDateTooltip = ({
  successfullySentTimestamp,
  successfullySent,
  sentTimestamp,
}: Props) => {
  const tooltipText = successfullySent
    ? formatFullDate(successfullySentTimestamp, "MMMM dd, yyyy, h:mm:ss a")
    : `This charge was sent to AWS on ${formatFullDate(
        sentTimestamp,
        "MMMM dd, yyyy, H:mm a"
      )} and is awaiting confirmation`;

  return (
    <Tooltip content={tooltipText} side="bottom" className="z-50 max-w-sm">
      <div
        className="select-text whitespace-nowrap underline decoration-dashed underline-offset-2"
        aria-label="Charge Date"
      >
        {successfullySent ? (
          <SuccessfullySentTimestamp timestamp={successfullySentTimestamp} />
        ) : (
          <PendingAWSConfirmation />
        )}
      </div>
    </Tooltip>
  );
};

const SuccessfullySentTimestamp = ({
  timestamp,
}: {
  timestamp: string | null;
}) => <>{formatFullDate(timestamp, "MMMM d")}</>;

const PendingAWSConfirmation = () => (
  <div className="flex items-center">
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className="h-1 w-1 animate-pulse"
    >
      <circle cx="10" cy="10" r="10" className="fill-gray-300" />
    </svg>
    <span className="ml-1 text-gray-400">Pending</span>
  </div>
);
