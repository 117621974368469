import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  GetMarketplaceMeteredChargesQuery,
  MarketplaceMeteringTransactionFilter,
} from "@m/api/v4/types";

export const GET_METERED_CHARGES = gql(/* GraphQL */ `
  query GetMarketplaceMeteredCharges(
    $where: MarketplaceMeteringTransactionFilter
  ) {
    marketplaceMeteringTransactions(where: $where) {
      edges {
        node {
          id
          company {
            id
          }
          dimension {
            id
            atlasName
          }
          quantity
          sentTimestamp
          marketplaceProductCode
          successfullySent
          successfullySentTimestamp
          account {
            id
            name
          }
        }
      }
    }
  }
`);

type MarketplaceMeteringTransactionsType = NonNullable<
  GetMarketplaceMeteredChargesQuery["marketplaceMeteringTransactions"]
>;
type MarketplaceMeteringTransactionEdgeType = NonNullable<
  MarketplaceMeteringTransactionsType["edges"][number]
>;
export type MeteredChargeType = NonNullable<
  MarketplaceMeteringTransactionEdgeType["node"]
>;

export const useMeteredCharges = (marketplaceSignupToken: string | null) => {
  const where: MarketplaceMeteringTransactionFilter = {
    marketplaceSignupToken,
  };

  const { data, ...result } = useQuery(GET_METERED_CHARGES, {
    variables: {
      where,
    },
  });

  const edges = data?.marketplaceMeteringTransactions?.edges ?? [];
  const meteredCharges: MeteredChargeType[] =
    edges
      .map((edge) => {
        return edge?.node;
      })
      .filter(
        (charge): charge is NonNullable<typeof charge> => charge !== null
      ) ?? [];

  return {
    data: { meteredCharges },
    ...result,
  };
};
