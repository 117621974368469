import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Suspensed, useModalState } from "@m/ui";

import { PATHS } from "@atlas/constants";

import { useCustomerSubscriptionDetails, useMeteredCharges } from "./api";
import {
  AddMeteredChargeModal,
  MeteredChargesTable,
  ProductInformationModal,
  SubscriptionDetailsTable,
} from "./components";

export const CustomerSubscriptionDetailsPage = () => {
  const navigate = useNavigate();

  const { customerId: id = "", marketplaceProductCode = "" } = useParams<{
    customerId: string;
    marketplaceProductCode: string;
  }>();

  const {
    data: { subscriptionDetails },
    loading: loadingSubscriptionDetails,
  } = useCustomerSubscriptionDetails(id, marketplaceProductCode);

  const {
    data: { meteredCharges },
    loading: loadingMeteredCharges,
  } = useMeteredCharges(subscriptionDetails?.signupToken || null);

  const meteredChargeModal = useModalState();
  const productInfoModal = useModalState();

  if (!id) navigate(generatePath(PATHS.ROOT));
  if (!subscriptionDetails?.catalogItem && !loadingSubscriptionDetails) {
    toast.error("Could not find customer subscription.");
    navigate(
      generatePath(PATHS.CUSTOMER_SUBSCRIPTIONS, {
        customerId: id,
      })
    );
  }

  const meteredChargeSubscriptionData = {
    customerIdentifier: subscriptionDetails?.customerIdentifier,
    displayName: subscriptionDetails?.catalogItem?.displayName,
    marketplaceProductCode: subscriptionDetails?.marketplaceProductCode,
  };

  return (
    <div className="flex flex-col">
      <div className="flex pl-1 text-base font-semibold text-default">
        <Suspensed
          loading={loadingSubscriptionDetails}
          height="24px"
          width="280px"
        >
          <h1>{subscriptionDetails?.catalogItem?.displayName}</h1>
          <Button
            type="button"
            fill="none"
            className="m-0 ml-1 !p-0"
            iconClassName="h-2 w-2"
            leftIcon={InformationCircleIcon}
            onClick={productInfoModal.open}
          ></Button>
        </Suspensed>
      </div>
      <SubscriptionDetailsTable
        loading={loadingSubscriptionDetails}
        subscriptionDetails={subscriptionDetails}
      />
      <hr />
      <MeteredChargesTable
        loading={loadingMeteredCharges}
        meteredCharges={meteredCharges}
        onAddMeteredCharge={meteredChargeModal.open}
      />
      <ProductInformationModal
        catalogItem={subscriptionDetails?.catalogItem ?? null}
        isOpen={productInfoModal.isOpen}
        onClose={productInfoModal.close}
      />
      {meteredChargeModal.isOpen && (
        <AddMeteredChargeModal
          isOpen={meteredChargeModal.isOpen}
          onClose={meteredChargeModal.close}
          subscriptions={[meteredChargeSubscriptionData]}
        />
      )}
    </div>
  );
};
