import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Button, FilterBar, Table } from "@m/ui";

import { MeteredChargeType, useSubscriptionManualDimensions } from "../api";

import { MeteredChargeDateTooltip } from "./MeteredChargeDateTooltip";

interface Props {
  meteredCharges: MeteredChargeType[];
  onAddMeteredCharge: () => void;
  loading: boolean;
}

export const MeteredChargesTable = ({
  meteredCharges,
  loading,
  onAddMeteredCharge,
}: Props) => {
  const { marketplaceProductCode = "" } = useParams<{
    customerId: string;
    marketplaceProductCode: string;
  }>();

  const {
    data: { dimensions: manuallyMeteredDimensions = [] },
  } = useSubscriptionManualDimensions(marketplaceProductCode);

  const rows = useMemo(() => {
    return meteredCharges.map((meteredCharge) => {
      return {
        dimension: meteredCharge?.dimension?.atlasName || "—",
        meteredUnits: meteredCharge?.quantity,
        chargeBy: meteredCharge?.account?.name || "—",
        chargeDate: (
          <MeteredChargeDateTooltip
            successfullySent={meteredCharge?.successfullySent}
            successfullySentTimestamp={meteredCharge?.successfullySentTimestamp}
            sentTimestamp={meteredCharge?.sentTimestamp}
          />
        ),
      };
    });
  }, [meteredCharges]);

  return (
    <div className="flex flex-col gap-3 pt-3">
      <div className="flex items-center justify-between gap-2">
        <FilterBar>
          <div className="text-sm font-semibold text-default">
            Metered Charges
          </div>
        </FilterBar>
        {manuallyMeteredDimensions.length > 0 && (
          <Button
            aria-label="Add Metered Charge"
            fill="subdued"
            kind="primary"
            onClick={onAddMeteredCharge}
            loading={loading}
          >
            Add Metered Charge
          </Button>
        )}
      </div>
      <Table
        headerFontSize="small"
        headers={TABLE_HEADERS}
        pageSize={30}
        loading={loading}
        rows={rows}
      />
    </div>
  );
};

const TABLE_HEADERS = [
  { label: "Dimension", accessor: "dimension" },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
    align: "right",
  },
  { label: "Charge By", accessor: "chargeBy" },
  { label: "Charge Date", accessor: "chargeDate", align: "right" },
];
